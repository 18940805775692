import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ServicesAndSpecialties = () => (
  <Layout>
    <Seo title="Services and Specialties" />
    <h1 id="top">Services and Specialties</h1>
    <p>Explore and learn about all of Marianne's services and specialties.</p>

    <p>
    Jump to:
      <a href="#counseling-services">Counseling Services</a> |
      <a href="#specialties-with-adults">Specialties with Adults</a> |
      <a href="#specialties-with-children">Adolescents</a> |
      <a href="#specialties-with-couples">Specialties with Couples</a> |
      <a href="#clinical-orientation">Clinical Orientation</a>
    </p>
    <p>
    Learn more:
      <a href="#mindfulness-meditation">Mindfulness Meditation</a> |
      <a href="#ecotherapy">Ecotherapy</a> |
      <a href="#mindful-relating">Mindful Relating</a> |
      <a href="#thought-field-therapy">Thought Field Therapy</a> |
      <a href="#emdr">Eye Movement Desensitization & Reprocessing</a> |
      <a href="#teachers-trainers-and-sources-of-inspiration">Teachers, Trainers, and Sources of Inspiration</a>
    </p>


    <h2 id="counseling-services">Counseling Services</h2>
    <ul>
      <li>Individual Therapy with Adolescents & Adults (ages 12-80 years)</li>
      <li>Couples Therapy</li>
      <li>Family Therapy</li>
      <li>Pre-marriage Counseling</li>
      <li>Climate-Informed Therapy</li>
      <li>Ecotherapy</li>
      <li>Grief Counseling</li>
      <li>Mindfulness Meditation</li>
      <li>EMDR</li>
      <li>TFT</li>

      <a href="#top">Back to top of page</a>
    </ul>


    <h2 id="specialties-with-adults">Specialties with Adults</h2>
    <ul>
      <li>Overcoming Worries, Fears & Anxiety ~ Relieving Depression</li>
      <li>Coping with Stressful Events ~ Adjusting to Life Transitions</li>
      <li>Moving Beyond Painful Past ~ Accessing Personal Strengths & Values</li>
      <li>Resolving Anger ~ Strengthening Emotional Intelligence</li>
      <li>Connecting Responsibility & Meaningfulness</li>
      <li>Balancing Self, Career & Family ~ Opening Creative Flow</li>
      <li>Dealing with Health Concerns & Pain ~ Generating Happiness in Daily Life</li>
      <li>Creating & Enhancing Meditation Practice</li>
      <li>Mindfulness Coaching</li>
      <li>Evolving Spiritual Consciousness</li>
      <a href="#top">Back to top of page</a>
    </ul>

    <h2 id="specialties-with-children">Specialties with Adolescents</h2>
    <ul>
      <li>Overcoming Worries, Fears & Anxiety ~ Relieving Depression</li>
      <li>Managing Intense Emotions ~ Strengthening Confidence & Positive Outlook</li>
      <li>Enhancing Self-Control ~ Solving Problems with Peers</li>
      <li>Increasing Communication & Listening ~ Improving School Performance</li>
      <li>Coping with Family Change & Stressful Situations</li>
      <li>Increasing Appropriate Expression of Emotions</li>
      <li>Getting Along with Siblings & Blended-Family Members</li>
      <li>Developing Healthy Relationship Patterns</li>
      <li>Encouraging Creativity & Engagement</li>
      <li>Accessing a Sense of Calm & Well-Being</li>
      <a href="#top">Back to top of page</a>
    </ul>

    <h2 id="specialties-with-couples">Specialties with Couples</h2>
    <ul>
      <li>Improving Communication ~ Resolving Conflicts</li>
      <li>Enhancing Intimacy ~ Balancing Career(s) & Family Life</li>
      <li>Strengthening Healthy Relationship Patterns</li>
      <li>Deepening Understanding & Appreciation ~ Healing through Forgiveness</li>
      <li>Fostering Kindness & Compassion</li>
      <li>Enjoying Differences in Perspective & Style</li>
      <li>Growing through Life’s Changes Together</li>
      <a href="#top">Back to top of page</a>
    </ul>

    <h2 id="clinical-orientation">Clinical Orientation</h2>

      <div className="three columns">
        <ul>
          <li>Transpersonal</li>
          <li>Family Systems</li>
        </ul>
      </div>
      <div className="three columns">
        <ul>
          <li>Interpersonal Neurobiology</li>
          <li>Contemplative</li>
          <li>Integral</li>
        </ul>
      </div>
      <div className="three columns">
        <ul>
          <li>Cognitive-Behavioral</li>
        </ul>
      </div>

      <div className="container" />
      <a href="#top">Back to top of page</a>

    <br />
    <br />
    <br />

    <h1>Additional Information</h1>

    <h2 id="mindfulness-meditation">Mindfulness Meditation</h2>
    <p>
      Mindfulness Meditation is a method of training the mind to relax and simply notice what is happening in the present moment. Judgment, worry, and planning are suspended; and space for sensing peacefulness and well-being is opened. In the course of practicing this kind attentiveness to one's inner experiencing, there is a natural expansion of a deep sense of connection and compassion. The kindness that is developed in relationship with oneself begins to show up in relationship with others as well.
    </p>
    <p>

    Research has shown that a sustained practice of Mindfulness Meditation:
    <ul>
       <li>Increases ability to focus and think clearly, even under stressful conditions</li>
       <li>Decreases feelings of anxiety, stress, and depression</li>
       <li>Improves physical health and performance</li>
       <li>Enhances creativity</li>
       <li>Expands compassion & feeling of connection within oneself & with others</li>
     </ul>
    </p>
    <p>
    Mindful awareness of physical and emotional sensations can lead to insights of patterns and perceptions that directly affect the experience of whatever situations come to us. The tone and quality of attention that is given to internal and external events directly affects how those events are experienced. In addition, recent research suggests that a consistent practice of mindful awareness can influence neurological changes in the brain itself, changes that lead to greater integration of information and energy as we move through a day's, or a life's, events.
    </p>
    <p>
    The positive effects of mindfulness meditation and mindful awareness are cumulative -- the more consistent and sustained the practice and implementation of these qualities into daily life, the greater the benefits. Establishing a practice of finding a peacefulness within, rather than basing one's sense of well-being on external circumstances, is particularly relevant in times of transition and uncertainty.
    </p>
    <p className="tagline"><i>
    "Mindfulness can become the single greatest source of equilibrium and clarity in your life."</i><br /> -- Daniel Siegel, MD (UCLA psychiatrist & expert in Interpersonal Neurobiology)
    </p>
    <p>
    For information about upcoming courses such as "Introduction to Mindfulness Meditation" or "Mindfulness as a Quality of Being: Working with Anxiety, Depression, & Anger," <Link to="/courses-retreats-and-special-events/">click here</Link>.
    </p>
    <p>
      More offerings can be found at <a href="http://montereybaymeditation.com">Monterey Bay Meditation Studio</a>
      <br /><a href="#top">Back to top of page</a>
    </p>



    <h2 id="ecotherapy">Ecotherapy</h2>
    <p>
      Especially in this time of environmental crisis and climate change, we sense disruption in our bodies, psyches and spirit. Ecotherapy engages direct, conscious  relationship with the healing power of Earth and all her beings as a bridge to re-integration and stabilization. As we remember and reconnect with the world outdoors, something deep-inside relaxes and a sense of belonging is revitalized. Catalyzing and cultivating awareness of our natural kinship with Earth activates compassion, healing and transformation.
    </p>
    <p>This shift is indicated by significant physiological, psychological and emotional changes such as decreased blood pressure, enhanced immune system, decreased stress, increased calm and feeling of well-being. Simply being outdoors -- stepping away from habits of doing, striving or accomplishing a goal -- is now being prescribed by physicians and other health-care professionals as part of treatment for heart conditions, diabetes, asthma, high blood pressure and many other ailments.
    </p>

    <p><i>“When stressed or overwhelmed, the immediate relief is always in the softening, and the surrendering. Your surrounding world already offers its medicine everywhere. Place your hands in the soil to feel grounded. Wade into water to feel cleansed and healed. Fill your lungs with fresh air to feel mentally clear. Raise your face to the heat of the sun to feel your own immense power. You are part of the earth, and comprised of the very same elements. And this is something you should remember.”</i> – Victoria Erickson</p>

    <p>Forest Bathing, Earthing, Sensory Awareness, and Mindfulness Meditation are a few of the  evidenced-based wellness-practices of Ecotherapy.
      <br /><br /><a href="#top">Back to top of page</a>
    </p>

    <h2 id="mindful-relating">Mindful Relating</h2>
    <p>
      Mindful Relating describes a way of being with another person (or persons) that engages vulnerability, transparency, and awareness of oneself and others in service of deep connection. With its focus on presence, acceptance, and equanimity, it is a practice of intersubjective meditation. At times this way of being in relationship leads to healing and transformation; it nearly always leads to deeper understanding and expansion of perspective.
    </p>
    <p>
      <i>"A conscious relationship is one that calls forth who you really are."</i> -- John Welwood
    </p>
    <p>Sourced in the teachings of Authentic World & Integral Philosophy, the focus of this relational practice is to be fully present and awake to what is happening with an attitude of openness and curiosity. The qualities of mindfulness and compassion are consciously engaged in service of deepening awareness of the field of connection. The invitation is to expand one's perspective to truly see the world as another person sees and experiences it, while simultaneously being conscious of the impact this awareness has on the self. This practice enhances awareness of the relational field as it is being co-created. In this field, there is discovery about oneself and others in order to be more profoundly here together.</p>
    <p>
      <i>"I think the greatest gift you can give someone is an honest receiving of what that person has to offer.” </i> -- Fred Rogers
    </p>
    <p>Marianne began her training with Authentic World in 2011. Since then, she has facilitated training intensives and weekend workshops in Boulder, CO, San Francisco and Santa Cruz, CA
    </p>

    <p>She offers Mindful Relating courses, workshops and retreats throughout the year and facilitates Games and Team Building Practices with organizations and businesses.</p>
    <p>
      <a href="#top">Back to top of page</a>
    </p>

    <h2 id="thought-field-therapy">Thought Field Therapy</h2>
    <p>
      The basis of Thought Field Therapy is the theory that thoughts and emotions create a psychological field in our awareness that can be accessed and affected by tapping specific points on the energy meridians of the body. It has been shown to be effective in the treatment of post-traumatic stress, anxiety, phobias, and persistent grief or anger.
    </p>
    <p>
      In a TFT session, the client is directed to gently tap specific points on the face, torso, and hands where the energies of specific emotions tend to be held. The sequence of the tapping is determined by the form of the emotional distress and is repeated until the distress is significantly reduced or gone. There is no focus on the "story" or cognitive elements of the sensation; the process is geared to re-balancing natural energy flow that has been interrupted by the specific emotional perturbation. The relaxation of emotional intensity is often quite rapid and surprising to the client. Once the energetic-emotional field is calm, shifts in cognitive beliefs, as well as other perceptions and patterns, can occur more easily.
      <br /><br /><a href="#top">Back to top of page</a>
    </p>

    <h2 id="emdr">Eye Movement Desensitization & Reprocessing</h2>

    <p>EMDR is a therapeutic technique that has been shown to be particularly effective in the treatment of post-traumatic stress. It has also been reported to be a part of successful treatment of panic attacks, anxiety, and disturbing memories. Similar to REM sleep, the eye back-and-forth eye movements (or bi-lateral audio or kinesthetic stimulation) of this therapeutic technique appear to facilitate the brain's natural processing systems to integrate events and intense emotions.</p>
<p>In an EMDR session, the therapist and client work together to identify the specific physical sensations, thoughts and emotions that are associated with or causing the distress, along with any negative beliefs or perceptions that have been formed due to the event. Also identified is a desired, positive belief to replace the limiting, negative perception. Then, a series of eye movements, hand taps or audio signals are facilitated by the therapist with the request that the client simply notice what sensations or thoughts come to mind. There are brief check-in's as the material and its corollaries are processed and becomes less disturbing. Gradually, there is a shift in the client's internal perception towards integration and resolution of the self and the event.</p>
<p>To determine if EMDR is an appropriate technique for a specific problem, the client and therapist meet for at least one consultation session prior to any EMDR sessions. If the client and therapist agree that EMDR would be a beneficial treatment or adjunct to "talk therapy," the EMDR sessions begin. The number of sessions required for the course of treatment depends on several factors including the complexity of the problem and its impact in the client's life.
<br /><br /><a href="#top">Back to top of page</a>
</p>

    <h2 id="teachers-trainers-and-sources-of-inspiration">Teachers, Trainers, and Sources of Inspiration</h2>
    <p>
      Marianne's therapeutic approach is informed by the work and teachings of Don Beck, Thomas Berry, Pema Chodron, Decker Cunov, Earth and All Her Beings, Charles Eisenstein, Milton Erickson, Matthew Fox, Viktor Frankl, Howard Gardner, Daniel Goleman, Thich Nhat Hahn, Rick Hanson, Harville Hendrix, His Holiness the XIVth Dalai Lama, Carl Jung, Jon Kabat-Zinn, Byron Katie, Fr. Thomas Keating, Jack Kornfield, Elisabeth Kubler-Ross, Stephen Levine, Joanna Macy, Abraham Maslow, Caroline Myss, Carl Rogers, Theodore Roszak, Marshall Rosenberg, Selwa Said, Sharon Salzberg, Virginia Satir, Richard Schwartz, Martin Seligman, Francine Shapiro, Daniel Siegel, Brian Swimme, Eckhart Tolle, Carl Whitaker, & Ken Wilber, among others.
    </p>

  </Layout>
)

export default ServicesAndSpecialties
